import Axios from "axios";
import { useQuery } from "react-query";
import LocalCache from "modules/components/client/v2/helpers";

export const useHasChatbotAccess = () =>
  useQuery({
    queryKey: ["chatbot-access"],
    queryFn: async () => {
      try {
        const access = LocalCache.get(`chatbot-access`);
        if (access) {
          return access;
        }

        const { data, status } = await Axios.get(
          "files/c5dd515f-9b74-454c-816e-f4582c937675"
        );
        LocalCache.set(`chatbot-access`, status === 200, 1000 * 60 * 4);
        return status === 200;
      } catch (e) {
        return false;
      }
    },
    keepPreviousData: true,
    placeholderData: (prev) => prev,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
