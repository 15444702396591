import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { SupportModal } from "components/Modal/supportModal";
import { SIconButtonMail } from "components/IconButton";
import ChatbotBox from "components/Modal/ChatbotBox";

import {
  setUserLocalStorage,
  profilePath,
  getUserLocalStorage,
  createUrl,
} from "util/util";
export const SupportContainerBot = ({ supportMail }) => (
  <Fragment>
    {/* <SIconButtonMail onClick={supportMail.toggle} path="ic-mail" /> */}
    {getUserLocalStorage() !== "Admin" && (
      <ChatbotBox iframeSrc="https://copilotstudio.microsoft.com/environments/Default-0159e9d0-09a0-4edf-96ba-a3deea363c28/bots/crad4_cbrExplorer/webchat?__version__=2" />
    )}
  </Fragment>
);

SupportContainerBot.propTypes = {
  supportMail: PropTypes.object.isRequired,
};
