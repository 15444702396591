import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { SupportModal } from "components/Modal/supportModal";
import { SIconButtonMail } from "components/IconButton";
import ChatbotBox from "components/Modal/ChatbotBox";
import { useHasChatbotAccess } from "queries/chatbot";
import { SupportContainerBot } from "./index-bot";

export const SupportContainerBasic = ({ supportMail }) => (
  <Fragment>
    <SupportModal onCancel={supportMail.toggle} isOpen={supportMail.isOpened} />
    <SIconButtonMail onClick={supportMail.toggle} path="ic-mail" />
  </Fragment>
);
export const SupportContainer = ({ supportMail }) => {
  const { data, isLoading } = useHasChatbotAccess();
  if (isLoading) return null;
  if (data) return <SupportContainerBot supportMail={supportMail} />;
  return <SupportContainerBasic supportMail={supportMail} />;
};
SupportContainer.propTypes = {
  supportMail: PropTypes.object.isRequired,
};
