import React, { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { connect } from "react-redux";
import { Icon } from "components/Icon";
import { ChatbotIcon } from "./chatbotIcon";
import { gtag } from "ga-gtag";
import { GA_CATEGORIES, GA_EVENTS } from "constants/gaEvents";

const ChatbotBox = ({
  tokenEndpoint = "https://default0159e9d009a04edf96baa3deea363c.28.environment.api.powerplatform.com/powervirtualagents/botsbyschema/crad4_buddy/directline/token?api-version=2022-03-01-preview",
  styleOptions = {},
  myProfile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(0);
  const chatRef = useRef(null);
  const directLineRef = useRef(null);
  const defaultStyleOptionsRef = useRef(null);
  const conversationRef = useRef(null);
  const [helpVisible, setHelpVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hasUserSentMessage, setHasUserSentMessage] = useState(false);
  const [chatVisible, setChatVisible] = useState(true);
  const activitySubscriptionRef = useRef(null);
  useEffect(() => {
    if (isOpen && chatRef.current) {
      while (chatRef.current.firstChild) {
        chatRef.current.removeChild(chatRef.current.firstChild);
      }
      initializeCopilot();
    }
  }, [isOpen, key]);

  useEffect(() => {
    setHelpVisible(true);
  }, []);

  useEffect(() => {
    setHelpVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const setupActivitySubscription = () => {
    if (!directLineRef.current) return;

    if (activitySubscriptionRef.current) {
      activitySubscriptionRef.current.unsubscribe();
    }

    activitySubscriptionRef.current = directLineRef.current.activity$.subscribe(
      {
        next: (activity) => {
          if (activity.type === "message" && !("role" in activity.from)) {
            setHasUserSentMessage(true);
          }
        },
        error: (error) => {},
      }
    );
  };

  const initializeCopilot = async () => {
    try {
      const apiVersion = new URL(tokenEndpoint).searchParams.get("api-version");
      const [directLineURL, token] = await Promise.all([
        fetch(
          new URL(
            `/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`,
            tokenEndpoint
          )
        )
          .then((response) => {
            if (!response.ok)
              throw new Error("Failed to retrieve regional channel settings.");
            return response.json();
          })
          .then(({ channelUrlsById: { directline } }) => directline),
        fetch(tokenEndpoint)
          .then((response) => {
            if (!response.ok)
              throw new Error("Failed to retrieve Direct Line token.");
            return response.json();
          })
          .then(({ token }) => token),
      ]);

      // Initialize DirectLine
      directLineRef.current = window.WebChat.createDirectLine({
        domain: new URL("v3/directline", directLineURL),
        token,
      });

      conversationRef.current = window.WebChat.createStore();

      defaultStyleOptionsRef.current = {
        backgroundColor: "#FFFFFF",
        bubbleBackground: "#eeeeee",
        bubbleFromUserBackground: STYLES_CONSTANTS.COLORS.PRIMARY_GREEN,
        textContent: "white",
        botAvatarImage: "/assets/img/buddy_icon.png",
        botAvatarInitials: "AI",

        bubbleBorderColor: STYLES_CONSTANTS.COLORS.PRIMARY_GREEN,
        accent: STYLES_CONSTANTS.COLORS.PRIMARY_GREEN,
        ...styleOptions,
      };

      // Render Web Chat
      window.WebChat.renderWebChat(
        {
          directLine: directLineRef.current,
          store: conversationRef.current,
          locale: "es",
          styleOptions: defaultStyleOptionsRef.current,
        },
        chatRef.current
      );

      const subscription = directLineRef.current.connectionStatus$.subscribe({
        next(value) {
          if (value === 2) {
            directLineRef.current
              .postActivity({
                localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                locale: "es",
                name: "startConversation",
                type: "event",
              })
              .subscribe();
            subscription.unsubscribe();
          }
        },
      });

      setupActivitySubscription();
    } catch (error) {
      console.error("Failed to initialize copilot:", error);
    }
  };
  useEffect(() => {
    if (hasUserSentMessage) {
      gtag("event", GA_EVENTS.INITIATING_CHATBOT, {
        category: GA_CATEGORIES.CHATBOT,
        action: `${GA_EVENTS.INITIATING_CHATBOT}`,
        label: "chatbot",
        data: myProfile.id,
      });
    }
  }, [hasUserSentMessage]);
  const handleRefresh = async () => {
    try {
      if (activitySubscriptionRef.current) {
        activitySubscriptionRef.current.unsubscribe();
        activitySubscriptionRef.current = null;
      }

      setHasUserSentMessage(false);

      if (directLineRef.current) {
        await directLineRef.current.end();
      }

      directLineRef.current = null;
      conversationRef.current = null;
      defaultStyleOptionsRef.current = null;

      if (chatRef.current) {
        while (chatRef.current.firstChild) {
          chatRef.current.removeChild(chatRef.current.firstChild);
        }
      }

      setChatVisible(false);

      setTimeout(() => {
        setKey((prevKey) => prevKey + 1);
        initializeCopilot();
        setChatVisible(true);
      }, 100);
    } catch (error) {
      console.error("Error refreshing chat:", error);
    }
  };

  useEffect(() => {
    return () => {
      if (activitySubscriptionRef.current) {
        activitySubscriptionRef.current.unsubscribe();
      }
    };
  }, []);

  return (
    <ChatbotContainer>
      {helpVisible && (
        <Fragment>
          <ChatHelpWindow
            className={isVisible ? "visible" : ""}
            onClick={() => {
              setIsVisible(false);
              setHelpVisible(false);
              setIsOpen(true);
            }}
          >
            <p>¡Hola! ¿Necesitas ayuda?</p>
          </ChatHelpWindow>
        </Fragment>
      )}
      {!isOpen && (
        <ChatHelpNotification
          className={isVisible ? "visible" : ""}
        ></ChatHelpNotification>
      )}
      {isOpen && (
        <ChatWindow isOpen={isOpen}>
          <ChatHeader>
            <HeaderTitle>Chatbot People</HeaderTitle>
            <HeaderButtons>
              <HeaderButton
                className="refresh-button"
                onClick={handleRefresh}
                title="Restart conversation"
              >
                <Icon
                  path={"ic_refresh"}
                  style={{
                    fill: "white !important",
                    color: "white",
                    width: "21px",
                    height: "16px",
                  }}
                  color={"white"}
                  alt={"Refresh"}
                />
              </HeaderButton>
              <HeaderButton
                className="close-button"
                onClick={() => setIsOpen(false)}
              >
                <Icon
                  path={"ic_cancel_search_2"}
                  style={{ stroke: "white !important", width: 15, height: 15 }}
                  alt={"Close"}
                />
              </HeaderButton>
            </HeaderButtons>
          </ChatHeader>
          {chatVisible && (
            <ChatboxContainer ref={chatRef} style={{ height: "100%" }} />
          )}
        </ChatWindow>
      )}
      <ChatButton
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIsVisible(false);
          setHelpVisible(false);
        }}
      >
        <ChatIcon>
          <img src={"/assets/img/buddy_icon.png"} alt="Chat bot" />
        </ChatIcon>
      </ChatButton>
    </ChatbotContainer>
  );
};

const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 400;
`;
const SIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
const ChatButton = styled.button`
  width: 60px;
  z-index: 400;
  height: 60px;
  border-radius: 30px;
  background-color: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  padding: 0;

  &:hover {
    transform: scale(1.1);
  }
`;

const ChatIcon = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 30px;
  box-shadow: -1px 1px 5px rgb(0 0 0 / 48%);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const ChatHelpWindow = styled.div`
  cursor: pointer;
  opacity: 0; // Start hidden
  transform: translateX(100%); // Start off-screen to the right
  transition: opacity 0.3s ease, transform 0.3s ease; // Transition for opacity and transform
  position: absolute;
  bottom: 10px;
  right: 65px;

  width: 200px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 15px;
  font-family: "Calibre-Medium", sans-serif !important;
  z-index: 1;
  p {
    font-family: "Calibre-Medium", sans-serif !important;
    opacity: 0.6;
  }
  &.visible {
    opacity: 1; // Fully visible
    transform: translateX(0); // Move to original position
  }
`;
const ChatHelpNotification = styled.div`
  cursor: pointer;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transition: opacity 0.3s ease, transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 7px;
  right: 9px;
  background: #17e88f;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 64%);
  overflow: hidden;
  padding: 5px;
  font-family: "Calibre-Medium", sans-serif !important;
  z-index: 1;
`;
const ChatWindow = styled.div`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: translateY(${(props) => (props.isOpen ? "0" : "20px")});
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 27vw;
  height: 65vh;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding-top: 50px;
`;

const ChatHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #012a2d;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 1;
`;

const HeaderTitle = styled.div`
  font-weight: 500;
  color: white;
  font-size: 20px;
  font-family: "FinancierBold", sans-serif !important;
`;
const ChatboxContainer = styled.div`
 @media (min-width: 1024px) {
    font-size: 16px;
  }
  @media (min-width: 1440px) {
    font-size: 20px;
  }

  .webchat__send-icon {
    background-image: url('/assets/svg/ic_send_message.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;  // adjust size as needed
  }
      .webchat__send-icon path {
    display: none !important;
  }
 div[class*="webchat__upload-button"] {
    display: none !important;
  }
    .webchat__bubble p:not(:last-child) {
    margin-bottom: 10px;
}
.webchat__bubble p strong {
  font-family: "Calibre-Medium", sans-serif !important;
}
  .webchat__render-markdown{
    line-height: 20px;
  }

    .webchat__render-markdown.webchat__render-markdown--message-activity > :first-child {
    margin-top: 0px;
}

div[class*="webchat__send-box__main"] {
    min-height: 70px !important;
}
     .webchat__main .webchat__upload-button {
    display: none !important;
  }
  .webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content {
      padding: 4px;
    background-color: #eeeeee !important
    border: 0px solid ${STYLES_CONSTANTS.COLORS.MAIN_GREY} !important;
    border-radius: 20px !important;
  }
  /* .webchat--css-oykdc-p2roxj.webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] */

  .webchat__adaptive-card-renderer .ac-pushButton, .webchat__suggested-action {
       background-color: #ffffff !important;
    border: 1px solid #012A2D !important;
    color: #012A2D !important;
    border-radius: 20px !important;
    transition: all 0.1s ease;
    &:hover {

      background-color: #012a2d !important;
      color: white !important;
    }
  }

  .webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] {
    background-color: ${STYLES_CONSTANTS.COLORS.ACCENT_GREEN} !important;

    border-color: ${STYLES_CONSTANTS.COLORS.ACCENT_GREEN} !important;
    color: black;
  }
  .webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] div {
    color: black !important;
  }
  .webchat__bubble.webchat__bubble--from-user .webchat__bubble__content {
    background-color: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN} !important;
    border-radius: 20px !important;
    border: 0px solid ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN} !important;
    min-width: 50px;
    text-align: center;
    padding: 6px;
  }
  .webchat__bubble.webchat__bubble--from-user p {
    color: white;
  }
`;
const HeaderButtons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  &.info-button {
    color: #0078d4;
    width: 24px;
    height: 24px;
    border: 2px solid #0078d4;
    border-radius: 50%;
    font-style: italic;
    font-family: serif;
    font-weight: bold;
  }
  &.refresh-button {
    padding-top: 8px;
  }
  &.close-button {
    color: white;
    font-size: 20px;
    font-weight: normal;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;

  &:hover {
    color: #333;
  }
`;
const mapStateToProps = (state) => ({
  myProfile: state.users.currentUser,
  serverInfo: state.user.serverStatus,
});
export default connect(mapStateToProps, null)(ChatbotBox);
